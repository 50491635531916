import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "Config/axios";

export const getDailySurvey = createAsyncThunk(
  "getDailySurvey",
  async (_, thunkAPI) => {
    const { rejectWithValue } = thunkAPI;

    try {
      const res = await axiosInstance.post(`/dashboard/survey/get_daily`, {
        name: "daily",
      });

      return res.data.survey;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

const getDailySurveySlice = createSlice({
  name: "getServey",
  initialState: { dailySurveyData: null, dailyLoading: false, error: null },

  reducers: {},
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed
    builder.addCase(getDailySurvey.pending, (state, action) => {
      // Add user to the state array
      state.dailyLoading = true;
      state.error = null;
    });
    builder.addCase(getDailySurvey.fulfilled, (state, action) => {
      // Add user to the state array
      state.dailyLoading = false;
      state.error = null;
      state.dailySurveyData = action.payload;
    });
    builder.addCase(getDailySurvey.rejected, (state, action) => {
      state.error = action.payload;
      state.dailyLoading = false;
      state.dailySurveyData = null;
    });
  },
});

export default getDailySurveySlice.reducer;
