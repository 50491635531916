import { axiosInstance } from "Config/axios";
import React, { useEffect, useState } from "react";

import { deleteAnswers } from "store/A&Q/deleteAnswer";
import { useDispatch } from "react-redux";
import UpdateAnswer from "./updateAnswer";
import DeleteConfrim from "Components/DeleteConfrim";
import { Spinner } from "react-bootstrap";

const Answers = (props) => {
  const dispatch = useDispatch();
  const [showUpdateAnswer, setShowUpdateAnswer] = useState({
    show: false,
    answerId: "",
  });
  const [deleteConfirmation, setDeletConfirmation] = useState({
    show: false,
    id: "",
  });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const getAnswer = async (id) => {
    setLoading(true);
    await axiosInstance
      .post("/dashboard/answers/index", { question_id: id })
      .then((res) => {
        setData(res.data.answers);
        setLoading(false);

        return res.data.answers;
      })
      .catch((error) => {
        setLoading(false);

        console.log(error);
      });
  };
  useEffect(() => {
    getAnswer(props.id);
  }, [props.id]);
  return (
    <>
      {loading ? (
        <div className="text-center    ">
          <Spinner animation="border" variant="" />
        </div>
      ) : (
        <>
          {data.length > 0 && (
            <ol type="a">
              {data.map((el) => (
                <div key={el.id}>
                  <div className="d-flex justify-content-between">
                    <li>{el.answer_text}</li>
                    <div>
                      <i
                        className="fa-solid fa-pen-to-square  pe-2 pointer"
                        onClick={() => {
                          setShowUpdateAnswer({ show: true, answerId: el?.id });
                        }}
                      ></i>
                      <i
                        className="fa-regular fa-trash-can text-danger  pointer"
                        onClick={() =>
                          setDeletConfirmation({ show: true, id: el?.id })
                        }
                      ></i>
                    </div>
                  </div>
                  <hr></hr>
                </div>
              ))}
            </ol>
          )}
        </>
      )}
      <UpdateAnswer
        surveyId={props.surveyId}
        data={showUpdateAnswer}
        setShow={() => setShowUpdateAnswer({ show: false, answerId: "" })}
      />
      <DeleteConfrim
        showModal={deleteConfirmation?.show}
        hideModal={() => setDeletConfirmation({ show: false, id: "" })}
        confirmModal={() =>
          dispatch(
            deleteAnswers({
              id: deleteConfirmation?.id,
              surveyId: props?.surveyId,
            })
          )
        }
      />
    </>
  );
};

export default Answers;
