import { createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance } from "Config/axios";

import { toast } from "react-toastify";

import { getTeacherDepartments } from "./getTeacherDepartments";

export const deleteTeacherDepartment = createAsyncThunk(
  "deleteTeacherDepartment",
  async (data, thunkAPI, _) => {
    const { dispatch } = thunkAPI;

    try {
      const res = await axiosInstance.post(
        `/dashboard/departments/delete_department_teacher`,
        {
          teacher_id: data.T_id,
          department_id: data.D_id,
        }
      );

      toast.success(res.data.message);
      dispatch(getTeacherDepartments(data.T_id));
      return res.data;
    } catch (error) {
      toast.error(error.response.data.message);
    }
  }
);
