import { Button, Form, Modal, Spinner } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { axiosInstance } from "Config/axios";
import { useDispatch, useSelector } from "react-redux";
import { getDepartments } from "store/departments/allDepartments";
import { getTeacherDepartments } from "store/TeacherDepartment/getTeacherDepartments";

const TeacherDepartmentForm = (props) => {
  const [loading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const { isLoading, DepartmentData } = useSelector(
    // @ts-ignore
    (state) => state.getDepartments
  );

  useEffect(() => {
    !DepartmentData && dispatch(getDepartments());
  }, [DepartmentData, dispatch]);
  const addDepartment = async (body) => {
    setIsLoading(true);
    await axiosInstance
      .post("/dashboard/departments/department_teacher", body)
      .then((res) => {
        setIsLoading(false);
        toast.success(res.data.message);
        dispatch(getTeacherDepartments(props.userId));
        formik.resetForm();
        props.setShow();
      })
      .catch((error) => {
        setIsLoading(false);
        error?.response?.status === 500
          ? toast.error("department added before")
          : toast.error(error.response.data.message);
      });
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      teacher_id: props?.userId,
      department_id: DepartmentData ? DepartmentData[0]?.id : "",
    },
    validationSchema: yup.object({
      department_id: yup.string().required(" required"),
    }),
    onSubmit: (values) => {
      addDepartment(values);
    },
  });
  const close = () => {
    formik.resetForm();
    props.setShow();
  };
  return (
    <>
      <Modal show={props.show} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Add Teacher Department</Modal.Title>
        </Modal.Header>
        {isLoading ? (
          <div className="text-center  my-5 py-5  ">
            <Spinner animation="border" variant="" />
          </div>
        ) : DepartmentData && DepartmentData.length > 0 ? (
          <Form onSubmit={formik.handleSubmit}>
            <Modal.Body>
              <Form.Group className="  col-12 my-3">
                <Form.Label>Department:</Form.Label>
                <Form.Select
                  name="department_id"
                  onChange={formik.handleChange}
                  value={formik.values.department_id}
                  onBlur={formik.handleBlur}
                >
                  {DepartmentData &&
                    DepartmentData.map((e) => (
                      <option value={e.id} key={e.id}>
                        {e.name}
                      </option>
                    ))}
                </Form.Select>
                {formik.errors.department_id &&
                  formik.touched.department_id && (
                    <Form.Text className=" text-danger" id="department_id">
                      {`${formik.errors.department_id}`}
                    </Form.Text>
                  )}
              </Form.Group>
            </Modal.Body>

            <Modal.Footer>
              <Button
                type="submit"
                variant="primary"
                className={loading ? "px-3" : ""}
                disabled={loading}
              >
                {" "}
                {loading ? (
                  <Spinner animation="border" variant="light" />
                ) : (
                  <div>Add</div>
                )}
              </Button>
            </Modal.Footer>
          </Form>
        ) : (
          <h6 className="text-center text-danger my-5 py-5  ">
            Add Department First
          </h6>
        )}
      </Modal>
    </>
  );
};

export default TeacherDepartmentForm;
