import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import { useState } from "react";

const DeleteConfrim = ({ showModal, hideModal, confirmModal }) => {
  const [loading, setLoading] = useState(false);

  const confirmdelete = () => {
    setLoading(true);
    confirmModal().then(() => {
      setLoading(false);

      hideModal();
    });
  };
  return (
    <Modal show={showModal} onHide={hideModal}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="alert alert-danger">Are you sure want to delete ?</div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="default" onClick={hideModal}>
          Cancel
        </Button>

        <Button
          variant="danger"
          className={loading ? "px-4" : ""}
          disabled={loading}
          onClick={() => confirmdelete()}
        >
          {" "}
          {loading ? (
            <Spinner animation="border" variant="light" />
          ) : (
            <div>Delete</div>
          )}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfrim;
