import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { getDepartments } from "store/departments/allDepartments";
import { Button, Card, Form } from "react-bootstrap";
import Loader from "Components/loader/loader";
import { getSurvey } from "store/servey/getSurvey";
import AddSurvey from "./addSurvey";
import { deleteSurvey } from "store/servey/deleteSurvey";
import UpdateSurvey from "./updateSurvey";
import { useNavigate } from "react-router-dom";
import { getDailySurvey } from "store/servey/getDailyServay";
import DeleteConfrim from "Components/DeleteConfrim";

const Servey = () => {
  const dispatch = useDispatch();
  const [department, setDepartment] = useState(null);

  const [showAddSurvey, setShowAddSurvey] = useState(false);
  const [showUpdateSurvey, setShowUpdateSurvey] = useState({
    show: false,
    id: "",
  });
  const [deleteConfirmation, setDeletConfirmation] = useState({
    show: false,
    id: "",
  });
  const [serveyType, setServeyType] = useState("daily");
  const isDaily = serveyType === "daily" ? true : false;
  const navigate = useNavigate();

  const { DepartmentData, isLoading } = useSelector(
    // @ts-ignore
    (state) => state.getDepartments
  );
  // @ts-ignore
  const { surveyData, loading } = useSelector((state) => state.getSurvey);

  const { dailySurveyData, dailyLoading } = useSelector(
    // @ts-ignore
    (state) => state.getDailySurvey
  );

  useEffect(() => {
    !DepartmentData && dispatch(getDepartments());
  }, [DepartmentData, dispatch]);
  useEffect(() => {
    department && dispatch(getSurvey(department));
  }, [dispatch, department]);

  useEffect(() => {
    isDaily && !dailySurveyData && dispatch(getDailySurvey());
    setDepartment(null);
  }, [dailySurveyData, dispatch, isDaily]);
  return (
    <div className="mt-5 p-4">
      <h4 className="pb-4 text-center">Survey</h4>

      <div className="mt-3 d-flex justify-content-center">
        <Button
          className="me-3"
          variant={isDaily ? "primary" : "outline-primary"}
          onClick={() => setServeyType("daily")}
        >
          <h6 className="m-1">Daily</h6>
        </Button>{" "}
        <Button
          className="ms-3"
          variant={!isDaily ? "primary" : "outline-primary"}
          onClick={() => setServeyType("department")}
        >
          <h6 className="m-1">Department</h6>
        </Button>
      </div>
      {isDaily && (
        <>
          <div className="text-end mt-4">
            <Button
              className="px-4 "
              style={{ fontWeight: "bold" }}
              variant="primary"
              type="submit"
              disabled={dailyLoading || dailySurveyData}
              onClick={() => setShowAddSurvey(true)}
            >
              <i className="fa-solid fa-plus pe-2"></i>
              Add Survey
            </Button>
          </div>
          {dailyLoading ? (
            <div style={{ marginTop: "-15vh" }}>
              <Loader />
            </div>
          ) : (
            <>
              {dailySurveyData ? (
                <Card className="mx-5 mt-4  p-2">
                  <Card.Body>
                    <Card.Title>{dailySurveyData?.name}</Card.Title>
                    <Card.Text>{dailySurveyData?.date}</Card.Text>
                  </Card.Body>

                  <div className="text-end ">
                    <Button
                      className="me-2"
                      variant="outline-primary"
                      onClick={() =>
                        setShowUpdateSurvey({
                          show: true,
                          id: dailySurveyData?.id,
                        })
                      }
                    >
                      <i className="fa-solid fa-pen-to-square  pe-2"></i>
                      Edit
                    </Button>
                    <Button
                      className="me-2"
                      variant="outline-primary"
                      onClick={() => {
                        navigate(`/questions&answers/${dailySurveyData?.id}`);
                      }}
                    >
                      <i className="fa-solid fa-circle-question fs-5 pe-2"></i>
                      Questions
                    </Button>
                    <Button
                      variant="outline-danger"
                      onClick={() =>
                        setDeletConfirmation({
                          show: true,
                          id: dailySurveyData?.id,
                        })
                      }
                    >
                      <i className="fa-regular fa-trash-can pe-2"></i>
                      Delete
                    </Button>
                  </div>
                </Card>
              ) : (
                <h5
                  className="w-100 text-center align-self-center "
                  style={{ marginTop: "25vh" }}
                >
                  No Survey
                </h5>
              )}
            </>
          )}
        </>
      )}
      {serveyType === "department" && (
        <div className="mt-4">
          {DepartmentData && DepartmentData.length > 0 ? (
            <div>
              <div className="d-flex justify-content-between">
                <Form.Group className="w-50 d-flex align-items-center">
                  <h5 className="me-1">Department: </h5>
                  <Form.Select
                    onChange={(el) => setDepartment(el.target.value)}
                  >
                    <option className="d-none"></option>

                    {DepartmentData &&
                      DepartmentData.length > 0 &&
                      DepartmentData.map((el) => (
                        <option key={el.id} value={el.id}>
                          {el.name}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
                {department && !loading && !surveyData && (
                  <Button
                    className="px-4 "
                    style={{ fontWeight: "bold" }}
                    variant="primary"
                    type="submit"
                    disabled={loading}
                    onClick={() => setShowAddSurvey(true)}
                  >
                    <i className="fa-solid fa-plus pe-2"></i>
                    Add Survey
                  </Button>
                )}
              </div>

              {loading || isLoading ? (
                <div style={{ marginTop: "-11vh" }}>
                  <Loader />
                </div>
              ) : department && surveyData ? (
                <div>
                  <Card className="mx-5 mt-5  p-2">
                    <Card.Body>
                      <Card.Title>{surveyData?.name}</Card.Title>
                      <Card.Text>{surveyData?.date}</Card.Text>
                    </Card.Body>

                    <div className="text-end ">
                      <Button
                        className="me-2"
                        variant="outline-primary"
                        onClick={() =>
                          setShowUpdateSurvey({
                            show: true,
                            id: surveyData?.id,
                          })
                        }
                      >
                        <i className="fa-solid fa-pen-to-square  pe-2"></i>
                        Edit
                      </Button>
                      <Button
                        className="me-2"
                        variant="outline-primary"
                        onClick={() => {
                          navigate(`/questions&answers/${surveyData?.id}`);
                        }}
                      >
                        <i className="fa-solid fa-circle-question fs-5 pe-2"></i>
                        Questions
                      </Button>
                      <Button
                        variant="outline-danger"
                        onClick={() =>
                          setDeletConfirmation({
                            show: true,
                            id: surveyData.id,
                          })
                        }
                      >
                        <i className="fa-regular fa-trash-can pe-2"></i>
                        Delete
                      </Button>
                    </div>
                  </Card>
                </div>
              ) : (
                <h5
                  className="w-100 text-center align-self-center "
                  style={{ marginTop: "30vh" }}
                >
                  No Survey
                </h5>
              )}
            </div>
          ) : (
            DepartmentData &&
            DepartmentData.length === 0 && (
              <h4
                className=" text-danger text-center"
                style={{ marginTop: "30vh" }}
              >
                Add Department First{" "}
              </h4>
            )
          )}
        </div>
      )}
      <AddSurvey
        show={showAddSurvey}
        setShow={() => setShowAddSurvey(false)}
        id={department}
        isDaily={isDaily}
      />
      <UpdateSurvey
        department={department}
        data={showUpdateSurvey}
        setShow={() => setShowUpdateSurvey({ show: false, id: "" })}
        isDaily={isDaily}
      />
      <DeleteConfrim
        showModal={deleteConfirmation.show}
        hideModal={() => setDeletConfirmation({ show: false, id: "" })}
        confirmModal={() =>
          dispatch(
            deleteSurvey({
              id: deleteConfirmation.id,
              userId: department,
            })
          )
        }
      />
    </div>
  );
};

export default Servey;
